.videoside {
  background-color: #5B7981;
  width: 300px;
  height: 100%;
}

.groupTitle {
  font-weight: bold;
  font-size: 1.25rem;
  text-transform: uppercase;
  border-bottom: 2px solid #999999;
  padding: 10px 10px 0 10px;
  color: white;
}
