.top {
	z-index: 100;
	height: 87px;
	background: white;
	position: fixed;
	left: 0px;
	right: 0px;
	top: 0px;
	box-shadow: 0px 6px 18px -6px rgb(0 0 0 / 30%);
}

.logodiv {
	padding: 8px 0 0 0;
}

.top div {
	max-width: 90%;
	margin: 0 auto 0 auto;
}

.logo {
	height: 58px;
	margin-top: 4px;
	border: 0;
}

.login {
	float: right;
	padding: 25px 20px;
}

.login a {
	color: black;
	text-decoration: none;
}

.icon {
	font-size: 30px;
	padding-right: 10px;
	vertical-align: bottom;
}