.sidemenu {
    color: white;
    font-size: 1.25rem;
    height: 100%;
    position: fixed;
    width: 200px;
}

.icon {
    width: 35px;
}

.el {
    padding: 10px 20px 10px 20px;
}

.uel {
    padding: 10px 20px 10px 65px;
    font-size: 1rem;
}

.sel {
    padding: 10px 20px 10px 20px;
    font-size: 1rem;
}


.el:hover, .uel:hover, .sel:hover {
    background-color: rgba(1, 1, 1, 0.2)
}

.line {
    border-top: 2px solid #999999;
}

.link {
    color: white;
    text-decoration: none;
}

.bottombox{
    position: fixed;
    bottom: 0;
    width: 200px;
}