.all {
  max-width: 1000px;
  position: relative;
  margin: 0 auto;
  padding-bottom: 40px;;
}

.footer {
  position:fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #5B7981;
  z-index: 100;
}

.link {
  margin: 0px 10px 0 10px;
  text-decoration: none;
  color: white;
}

.timeout {
  max-width: 1000px;
  margin: 0 auto;
}

.overlay{
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: rgba(158, 0, 0, 0.8);
  font-weight: bold;
  font-size: 20px;
  color: white;
  padding: 20px;
  z-index: 10;
}