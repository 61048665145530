.container {
	display: grid;
	grid-template-columns: 200px 1fr;
	grid-template-rows: 1fr;
	grid-gap: 0em 0em;
}

.main {
	grid-area: 1 / 2 / 2 / 3;
}

.sidemenu {
	grid-area: 1 / 1 / 2 / 2;
}



.container {
	height: 100%;
}

.sidemenu {
	background: #2B363E;
	padding: 97px 0 0 0;
}

.main {
	padding: 107px 20px 20px 20px;
}