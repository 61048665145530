.all {
  max-width: 1300px;
  margin: 0 auto;
}

.container {
	display: grid;
	grid-template-columns: 1fr 300px;
	grid-template-rows: 1fr;
	grid-gap: 0px 0px;
}

.videoarea {
  grid-area: 1 / 1 / 2 / 2;
}

.textarea {
	grid-area: 1 / 2 / 2 / 3;
}

.player {
  /* position: relative; */
}