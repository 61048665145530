.body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.timeout {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
}

.overlay{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: rgba(158, 0, 0, 0.8);
  font-weight: bold;
  font-size: 20px;
  color: white;
  padding: 20px;
  z-index: 1000;
}