.title {
    font-weight: bold;
    font-size: 1.25rem;
    text-transform: uppercase;
    border-bottom: 2px solid #999999;
    margin-bottom: 20px;
}

.front {
    max-width: 1360px;
    margin: 0 auto;
}