.all {
  text-align: center;
  padding-top: 50px;
}

.footer {
  text-align: left;
  position:fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #5B7981;
  z-index: 100;
}

.link {
  margin: 0px 10px 0 10px;
  text-decoration: none;
  color: white;
}
