.element {
  padding: 10px;
  color: white;
  cursor: pointer;
}

.element:hover {
  background-color: rgba(1, 1, 1, 0.2);
}

.selected {
  background-color: rgba(1, 1, 1, 0.2);
}

.imagecontainer {
  width: 100px;
  overflow: hidden;
  display:inline-block;
  height: 56px;
}

.image {
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  transition: all .2s;
}

.image:hover {
  transform: scale(1.1);
}

.label {
  padding-left:10px;
  font-size: 0.9rem;
}