.link {
    color: black;
    text-decoration: none;
}

.linkbox {
    width: 320px;
    margin: 0 20px 20px 0;
    display: inline-table;
    font-size: 0.9rem;
}

.main {
    border: 1px solid #2B363E;
    overflow: hidden;
    height: 180px;
}

.name {
    padding: 5px 0 1px 0;
    font-size: 0.95rem;
    font-weight: bold;
}

.dato {
    padding-top: 5px;
    font-size: 0.9rem;
}

.cover {
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    transition: all .2s;
}

.cover:hover {
    transform: scale(1.1);
}
