.box {
  margin-top: 20px;
  border: 1px solid #D9D9D9;
  overflow:hidden;
  max-height: 51px;
  transition: max-height 0.3s;
}

.opened {
  max-height: 400px;
}

.title {
  background-color: #5B7981;
  padding:10px;
  margin:2px;
  color: white;
}

.main {
  padding: 0 10px;
}

.link {
  color: black;
  text-decoration: none;
  font-size: 0.9rem;
}