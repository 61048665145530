.videobottom {
  /* background-color: #D9D9D9; */
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #D9D9D9;
  border-radius: 0 0 0 4px;
  padding: 10px;
  margin-top: -4px;
}

.title {
  font-weight: bold;
  font-size: 1.25rem;
  text-transform: uppercase;
  border-bottom: 2px solid #999999;
  padding-right: 50px;
}

.text {
  font-size: 1rem;
  padding-top: 10px;
}